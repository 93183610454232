




import { Component, Vue } from 'vue-property-decorator'
import CollaborationDetail from './components/CollaborationDetail.vue'

@Component({
  name: 'EditCollaboration',
  components: {
    CollaborationDetail
  }
})
export default class extends Vue {}
